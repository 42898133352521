<template>
    <ul v-if="searched.length" class="uk-grid uk-grid-medium uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@l uk-child-width-1-4@xl" data-uk-grid>

    <li v-for="folder in folders" :key="folder.name">
        <div class="uk-panel uk-panel-box uk-text-center" @click.prevent="toggleSelect(folder.name)">
            <div class="uk-panel-teaser uk-position-relative">
                <div class="uk-cover-background uk-position-cover thumbnail-folder"></div>
                <div class="uk-position-center uk-light">
                  <span data-uk-icon="icon:folder;ratio:3"></span>
                </div>
                <canvas class="uk-responsive-width uk-display-block" width="1200" height="800"></canvas>
            </div>
            <div class="uk-text-truncate">
                <label>
                <input class="uk-checkbox" type="checkbox" :value="folder.name" v-model="selected" @click.stop>
                </label>
                <a @click.stop="setPath(folder.path, $event)"> {{ folder.name }}</a>
            </div>
        </div>
    </li>

    <li v-for="file in files" :key="file.name">
        <div class="uk-panel uk-panel-box uk-text-center" @click.prevent="toggleSelect(file.name)">
            <div class="uk-panel-teaser uk-position-relative">
                <div class="uk-background-contain uk-position-cover uk-background-norepeat" v-if="isImage(file.path)" :style="'background-image:url(' + file.url + ')'"></div>
                <div class="uk-cover-background uk-position-cover pk-thumbnail-file" v-else></div>
                <canvas class="uk-responsive-width uk-display-block" width="1200" height="800"></canvas>
            </div>
            <div class="uk-text-nowrap uk-text-truncate">
                <label>
                <input class="uk-checkbox" type="checkbox" :value="file.name" v-model="selected" @click.stop>
                 {{ file.name }}
                </label>
            </div>
        </div>
    </li>

</ul>
</template>
<script>
export default {
  props: {
    searched: {
      required: true,
      type: Array
    },
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    folders() {
      return _.filter(this.searched, file => {
        return file.mime === 'application/folder'
      })
    },
    files() {
      return _.filter(this.searched, file => {
        return file.mime === 'application/file'
      })
    },
  },
  watch: {
    selected() {
      this.$emit('selected', this.getSelected())
    }
  },
  methods: {
    setPath (path) {
      this.$emit('setPath', path);
    },
    getSelected() {
      return this.selected.map(name => {
        return _.find(this.searched, s => s.name === name).name
      })
    },
    isSelected(name) {
      return this.selected.indexOf(name.toString()) != -1;
    },
    toggleSelect(name) {
      let index = this.selected.indexOf(name);
      -1 === index ? this.selected.push(name) : this.selected.splice(index, 1);
    },
    isImage (url) {
      return url.match(/\.(?:gif|jpe?g|png|svg|ico)$/i);
    },
  },
}
</script>  